import request from '../utils/request';

/**
 * 积分商城：查询商品
 * @param param
 * @returns {*}
 */

export const selectProducts = (param) => {
    return request({
        url: '/sys/commodity/getPageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 积分商城：创建商品
 * @param item
 * @returns {*}
 */
export const createdProducts = (item) => {
    return request({
        url: '/sys/commodity/add',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(item),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 积分商城：修改商品
 * @param item
 * @returns {*}
 */
export const updateProducts = (item) => {
    return request({
        url: '/sys/commodity/update',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(item),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 积分商城：查询用户积分明细
 * @param param
 * @returns {*}
 */

export const selectIntegralDetail = (param) => {
    return request({
        url: '/sys/scoreDetails/getPageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 积分商城：查询用户奖品明细
 * @param param
 * @returns {*}
 */

export const selectPrizeDetail = (param) => {
    return request({
        url: '/sys/commodityRecord/getPageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};


/**
 * 积分商城：查询规则列表
 * @param param
 * @returns {*}
 */

export const selectRule = (param) => {
    return request({
        url: '/sys/scoreRule/getPageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 积分商城：添加获得积分规则
 * @param param
 * @returns {*}
 */

export const createdRule = (param) => {
    return request({
        url: '/sys/scoreRule/add',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 积分商城：更新获得积分规则
 * @param param
 * @returns {*}
 */

export const updateRule = (param) => {
    return request({
        url: '/sys/scoreRule/update',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 积分商城：删除获得积分规则
 * @param param
 * @returns {*}
 */

export const deleteRule = (id) => {
    return request({
        url: `/sys/scoreRule/delete/${id}`,
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};