<template>
  <div style="padding: 20px">
    <div style="margin: 10px 0 20px 0">
      <el-input
          :size="size"
          v-model="query.userId"
          placeholder="请输入用户id"
          style="width: 160px;margin-right: 10px"
      ></el-input>
      <el-button style="margin:0 10px" type="primary" @click="select(0)">查 询</el-button>
      <el-button type="primary" @click="addGainIntegralRule">新 增</el-button>
    </div>
    <el-table
        :data="IntegralList"
        border
        :row-style="{height:20+'px'}"
        :cell-style="{padding:0+'px'+ 0 +'px'}"
        class="table"
        ref="multipleTable"
        height="626px"
        header-cell-class-name="table-header"
    >
      <el-table-column show-overflow-tooltip="true" align="center" prop="ruleName" label="规则名称"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="score" label="获得积分"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="startTime" label="开始时间"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="endTime" label="结束时间"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="ruleDetails" label="规则说明"/>
      <el-table-column show-overflow-tooltip="true" align="center" prop="state" label="单位">
        <template v-slot="scope">
          {{scope.row.unit == 1 ? '积分':'倍数'}}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="createTime" label="创建时间"/>
      <el-table-column label="操作"  width="100" align="center">
        <template #default="scope">
          <div style="display: flex;justify-content: space-around">
            <el-button
                size="small"
                type="text"
                @click="updateItem(scope.row)">编辑</el-button>
            <el-button
                size="small"
                type="text"
                style="color: red"
                @click="deleteItem(scope.row.id)">删除</el-button>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <add-gain-integral-rule ref="addGainIntegralRule"/>
    <update-gain-integral-rule ref="updateGainIntegralRule"/>
  </div>
</template>

<script>
import {selectRule,deleteRule}from "@/api/mall.js";
import addGainIntegralRule from "./components/AddGainIntegralRule";
import updateGainIntegralRule from "./components/UpdateGainIntegralRule";
export default {
  components: {
    addGainIntegralRule,
    updateGainIntegralRule
  },
  name: "GainIntegralRule",
  data() {
    return {
      query:{
        endTime: "",
        pageIndex: 0,
        pageSize: 10,
        ruleName: "",
        startTime: ""
      },
      IntegralList:[],
      pageTotal:0,
    }
  },
  methods:{
    handlePageChange(index) {
      this.select(index);
    },
    addGainIntegralRule(){
      this.$refs.addGainIntegralRule.openDialog();
    },
    updateItem(item){
      this.$refs.updateGainIntegralRule.openDialog(item);
    },
    select(index){
      if (index){
        this.query.pageIndex = index
      }
      selectRule(this.query).then(val=>{
        if (val.code == 200){
          this.IntegralList = val.data.records;
          this.pageTotal = val.data.total
        }
      })
    },
    deleteItem(id){
      deleteRule(id).then(val=>{
        if (val.code == 200){
          this.$message.success(val.message);
          this.select(0);
        }else {
          this.$message.warning(val.message);
        }
      })
    }
  },
  created() {
    this.select();
  }
}
</script>

<style scoped>

</style>