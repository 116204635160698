<template>
  <el-dialog
      v-model="updateRuleDialog"
      title="添加商品"
      width="1050px"
  >
    <div style="margin-right: 50px;height: 400px">
      <el-form :model="rule" :rules="Rules" ref="Rules" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="规则名称" prop="ruleName">
              <el-input style="width: 200px" v-model="rule.ruleName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="积分类型" prop="unit">
              <el-select style="width: 200px" v-model="rule.unit" class="m-2" placeholder="请选择" size="large">
                <el-option key="1" label="积分"  :value="1"/>
                <el-option key="2" label="倍数"  :value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="16">
            <el-form-item label="有效时间" prop="startEndTime">
              <el-date-picker
                  style="width: 100%"
                  v-model="rule.startEndTime"
                  type="datetimerange"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  format="YYYY/MM/DD HH:mm:ss"
                  value-format="x"
                  date-format="YYYY/MM/DD HH:mm:ss"
                  time-format="hh:mm:ss"
              />
            </el-form-item>
          </el-col>
<!--          <el-col :span="8">-->
<!--            <el-form-item label="开始日期" prop="startTime">-->
<!--              <el-date-picker  type="date" style="width:200px" value-format="yyyy-MM-dd" v-model="rule.startTime"-->
<!--                               placeholder="开始日期">-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="结束日期" prop="endTime">-->
<!--              <el-date-picker  type="date" style="width:200px" value-format="yyyy-MM-dd" v-model="rule.endTime"-->
<!--                               placeholder="结束日期">-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span="8">
            <el-form-item class="itemWih" label="获得积分" prop="score">
              <el-input style="width: 200px" v-model="rule.score" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item class="itemWih" label="规则说明" prop="ruleDetails">
              <el-input
                  v-model="rule.ruleDetails"
                  :rows="2"
                  type="textarea"
                  placeholder="请输入规则详细描述。"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addProductsDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import {updateRule}from "@/api/mall.js";
export default {
  name: "UpdateGainIntegralRule",
  data() {
    return {
      updateRuleDialog:false,
      productsClassify:[],
      rule:{
        endTime:null,
        ruleDetails: "",
        ruleName: "",
        score: 0,
        startTime: null,
        unit: null,
        startEndTime:[],
      },
      Rules: {
        ruleName: [{
          required: true,
          message: '请输入规则名称！',
          trigger: 'blur'
        },],
        ruleDetails: [{
          required: true,
          message: '请选择规则描述！',
          trigger: 'change'
        },],
        score: [{
          required: true,
          message: '请输入获得积分！',
          trigger:'change'
        }],
        unit: [{
          required: true,
          message: '请选择积分类型！',
          trigger:'change'
        }],
        startEndTime: [{
          required: true,
          message: '请选择时间！',
          trigger: 'blur'
        }],
      },
    }
  },
  methods:{
    openDialog(item) {
      this.rule = JSON.parse(JSON.stringify(item));
      this.rule.startEndTime = [];
      this.rule.startEndTime.push(this.rule.startTime);
      this.rule.startEndTime.push(this.rule.endTime);
      this.updateRuleDialog = true;
    },
    dateFormat(val) {
      let myDate = new Date(val);
      let year = myDate.getFullYear(); //年
      let month = myDate.getMonth() + 1; //月
      let day = myDate.getDate(); //日
      let hh = myDate.getHours(); //时
      let mm = myDate.getMinutes();
      let ss = myDate.getSeconds();
      const value = year + "-" + month + "-" + day + " " + hh + ":" + mm + ":" + ss;
      return value;
    },
    confirm(){
      this.$refs.Rules.validate((valid)=>{
        if(valid){
          this.rule.startTime = this.dateFormat(this.rule.startEndTime[0]);
          this.rule.endTime = this.dateFormat(this.rule.startEndTime[1]);
          updateRule(this.rule).then(val=>{
            if (val.code == 200){
              this.$message.success(val.data);
              this.updateRuleDialog = false;
            }else {
              this.$message.warning(val.message);
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>